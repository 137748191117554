<template>
    <span v-if="$can($acl.action.Delete, $acl.subjects.Accounting)"
            v-b-tooltip.hover.top="
              $t('Management.Accounting.AccountingPrint')
            "
          >
        <feather-icon
        icon="PrinterIcon"
        class="hover-green"
        size="16"
        @click="print"
        />
    </span>
</template>
<script>
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable'
import { BFormInput, BButton, BModal, BFormGroup} from "bootstrap-vue";
import train from "../../../../../assets/images/pdf/Gemeinde_Train.png";
import { costFormatter, weightFormatter } from '@/utility/format/formatter';


export default {
    components:{BButton, BFormGroup, BModal, BFormInput},
    props:{
        row: {
            type: Object,
            default: () => null,
        },
    },
    data: () => ({
        train
    }),

    methods: {
        async print(){
            const { data } = await this.$axios.post('certificates/accountingcertificates', this.row)
            if(data){
                this.row.certificates = data;
            }
            const translationsRoot = "Management.Accounting";
            const doc = new jsPDF();
            const pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
            const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
            const center = pageWidth / 2;

            let pageCount = Math.ceil(this.row.certificates.length / 34);
            let certificatesBody = [];
            let sums = [];
            let sum = 0;
            let weights = [];
            let weight = 0;
            let pageIndex = 0;
            
            for (let index = 0; index < pageCount; index++) {
                certificatesBody.push([]);
                sums.push(0);
                weights.push(0);
            }


            for (let index = 0; index < this.row.certificates.length; index++) {
                if(!this.row.certificates[index].cancelled){
                    const cert = this.row.certificates[index];

                    if(index % 34 === 0 && index > 0){
                        sums[pageIndex] = sum;
                        weights[pageIndex] = weight;
                        pageIndex++;
                    }
                    if (this.row.processType === "VA") {
                        certificatesBody[pageIndex].push([new Date(cert.date).toLocaleDateString("fr-CH"), cert.processes[0].processId, cert.certificateId, cert.stockId, cert.orderId, weightFormatter.format(cert.weight), costFormatter.format(cert.costs)])
                    } else {
                        certificatesBody[pageIndex].push([new Date(cert.date).toLocaleDateString("fr-CH"), cert.processes[0].processId, cert.certificateId, cert.packingOrderId, cert.orderId, weightFormatter.format(cert.weight), costFormatter.format(cert.costs)])
                    }
                    
                    sum+=cert.costs;
                    weight+=cert.weight;

                    if(index + 1 === this.row.certificates.length){
                        sums[pageIndex] = sum;
                        weights[pageIndex] = weight;
                    }
                }
             
            }
            if(certificatesBody.length === 0){
                certificatesBody.push([]);
                certificatesBody[0].push(["", "", "", "", ""]);
            }
            for (let index = 0; index < certificatesBody.length; index++) {
                const body = certificatesBody[index];
                doc.setFont("Helvetica", "normal")
                doc.addImage({imageData: this.train, x: 10, y: 5, width: 25, height: 25, format: "PNG"});
                doc.setFontSize(13);
                doc.text(this.row.processType === "VA" ? "VA" : "EX", 5, 8);
                doc.text(this.$t(`${translationsRoot}.Seal_Subtitle`), 7, 35);
                doc.setFontSize(11);
                doc.text(this.$t(`${translationsRoot}.Page`) + `: ${index+1}/${pageCount}`, pageWidth - 20, 8, "center");

                doc.text(this.$t(`${translationsRoot}.HopfenveredlungAdress.1`), center + 45, 15, "center");
                doc.text(this.$t(`${translationsRoot}.HopfenveredlungAdress.2`), center + 45, 21, "center");
                doc.text(this.$t(`${translationsRoot}.HopfenveredlungAdress.3`), center + 45, 26, "center");
                if(body){
                    body.push(["","", "", "", ""]);
                    body.push(["","", this.$t(`${translationsRoot}.Sum`), weightFormatter.format(weights[index]), costFormatter.format(sums[index])]);
                }

                
                
                doc.setFont("Helvetica", "bold");
                doc.setFontSize(18);
                const titleInfo = ` ${this.$t(`${translationsRoot}.Months.${this.row.month}`)}/${this.row.year} - ${this.row.shareholder}`
                doc.text(this.$t(`${translationsRoot}.Title`) + titleInfo , center, 50, "center");
                const header = [[
                    this.$t(`${translationsRoot}.Header.Date`), 
                    this.$t(`${translationsRoot}.Header.ProcessId`), 
                    this.$t(`${translationsRoot}.Header.CertificateId`), 
                    this.$t(`${translationsRoot}.Header.Charge`), 
                    this.$t(`${translationsRoot}.Header.Submission`), 
                    this.$t(`${translationsRoot}.Header.Weight`),
                    this.$t(`${translationsRoot}.Header.Costs`),
                ]];
                
                autoTable(doc, {
                    margin: { left: 5, right: 5 },
                    startY: 55 ,
                    head:header,
                    body: body,
                    theme:"plain",
                    columnStyles: { 0: {}, 
                                    1: {},
                                    2: {},
                                    3: {},
                                    4: {},
                                    5: {halign: "right"},
                                    6: {halign: "right"}},
                    didParseCell: data => {
                        if (data.cell && data.cell.section === "head") {
                            data.cell.styles.lineWidth = .3;
                            data.cell.styles.lineColor = (0,0,0);
                            if(data.cell.text[0] === this.$t(`${translationsRoot}.Header.Weight`) || data.cell.text[0] === this.$t(`${translationsRoot}.Header.Costs`))
                            {
                                data.cell.styles.halign = "right"
                            }
                        }   
                        if (data.cell && data.cell.section === "body") {
                            data.cell.styles.lineWidth = 0;
                            data.cell.styles.cellPadding = {top: 1, right: 1, bottom: 1, left: 1 };
                        } 
                    },
                    didDrawCell: data => {
                        if (data.row.index === body.length - 1) {
                            if(data.cell.raw === this.$t(`${translationsRoot}.Sum`)){
                                doc.setLineWidth(0.5);
                                doc.line(data.cell.x, data.cell.y, pageWidth - 2, data.cell.y);
                            }
                        }
                    }
                });

                if(certificatesBody[index + 1] !== undefined){
                    doc.addPage();
                }
            }
                    
            window.open(doc.output('bloburl'), '_blank'); 
        },

    }
}
</script>
<stlye lang="scss">
    .from-to-container {
        margin-top: 1rem;
        display: flex;
        justify-content: space-between;
    }
</stlye>